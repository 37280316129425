@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;700&family=Spectral:wght@200;400;700&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Karla", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
h1,
h2 {
  font-family: "Spectral";
}
body {
  background-color: #fefefe;
  height: 80vh;
  overflow: hidden;
}
p {
  font-size: 14px;
}
#map * {
  overflow: visible !important;
}
.ReactQueryDevtools {
  width: auto !important;
}
